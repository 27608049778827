<template>
    <div>
        <div ref="pdf" class="card-block statement dark-text">
            <button class="invoice-back text-button no-bg pointer" @click="$router.back()">
                <span class="material-icons-outlined">arrow_back</span> Back
            </button>
            <h2 class="align-center bottom-20">STATEMENT</h2>
            <hr class="gray top-0 bottom-20" />
            <div class="flex center space-between bottom-20">
                <div v-if="company" class="">
                    <p>{{ company.company_name }}</p>
                    <p>{{ company.address }}</p>
                    <p>{{ company.city }}, {{ company.state }} {{ company.zip }}</p>
                </div>
                <div v-if="company">
                    <DynamicImage alt="logo" :urlToRetrieveFile="company.logo ? file.getFile(company.logo) : null" class="company-logo" />
                </div>
            </div>
            <hr class="gray top-0 bottom-20" />

            <div>
                <h3 class="inline-block">Issued Date:</h3>
                <p class="inline-block">&nbsp;{{ today }}</p>
            </div>
            <div>
                <h3 class="inline-block">Statement Range:</h3>
                <p class="inline-block">
                    &nbsp;{{ this.getFormattedDate(this.startDate) }} - {{ this.getFormattedDate(this.endDate) }}
                </p>
            </div>

            <hr class="gray top-0 bottom-20" />

            <div v-if="client" class="bottom-20">
                <p>
                    <b>{{ client.first_name }} {{ client.last_name }}</b>
                </p>
                <p>{{ client.street_address }}</p>
                <p>{{ client.city }}, {{ client.state }} {{ client.zip }}</p>
            </div>

            <table class="bottom-20 statement-table">
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Description</th>
                        <th>Charges</th>
                        <th>Payments</th>
                        <th>Balance</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(row, i) in statement" :key="row.date">
                        <td>
                            <strong v-if="i === 0 || i === statement.length - 1">{{
                                getFormattedDate(row.date)
                            }}</strong>
                            <span v-else>{{ getFormattedDate(row.date) }}</span>
                        </td>

                        <td>
                            <strong v-if="i === 0 || i === statement.length - 1">{{ row.description }}</strong>
                            <span v-else>{{ row.description }}</span>
                        </td>

                        <td>
                            <span>{{ row.charge ? $getCurrency(row.charge) : null }}</span>
                        </td>

                        <td>
                            <span>{{ row.payment ? $getCurrency(row.payment) : null }}</span>
                        </td>

                        <td>
                            <strong v-if="i === 0 || i === statement.length - 1">{{
                                $getCurrency(row.balance, true)
                            }}</strong>
                            <span v-else>{{ $getCurrency(row.balance, true) }}</span>
                        </td>
                    </tr>
                </tbody>
            </table>

            <div class="bottom-20 align-right">
                <p><strong>Total Charges:</strong> {{ $getCurrency(totalCharges) }}</p>
                <p><strong>Total Payments:</strong> {{ $getCurrency(totalPayments) }}</p>
            </div>
            <div>
                <button class="secondary" @click="viewAsPdf">Preview as PDF</button>
                <button class="secondary" @click="sendPdfToClientFiles">Save PDF to Client Files</button>
            </div>
        </div>
    </div>
</template>

<script>
    import { file, ledger } from '@/util/apiRequests';
    import dayjs from '@/util/dayjs';
    import { createRawBuffer, downloadPdf, openPdf } from '@/util/pdf';
    import DynamicImage from '@/components/general/image/Image';

    export default {
        name: 'Statement',
        components: { DynamicImage },
        data() {
            return {
                client: null,
                company: null,
                statement: [],
                totalCharges: 0,
                totalPayments: 0,
                statementSaved: false,
                file
            };
        },
        computed: {
            clientId() {
                return this.$route.query.c;
            },
            startDate() {
                return this.$route.query.s.split('T')[0];
            },
            endDate() {
                return this.$route.query.e.split('T')[0];
            },
            today() {
                return dayjs().format('MM/DD/YY');
            }
        },
        methods: {
            async getStatement() {
                if (!this.clientId || !this.startDate || !this.endDate) {
                    this.$toasted.error('Cannot generate statement without client ID, start date and end date');
                    return;
                }

                try {
                    const res = await this.$api.get(ledger.getStatement(this.clientId, this.startDate, this.endDate));

                    this.client = res.data.client;
                    this.company = res.data.company;
                    this.statement = res.data.statement;
                    this.totalCharges = res.data.totalCharges;
                    this.totalPayments = res.data.totalPayments;
                } catch (err) {
                    this.$toasted.error('Could not retrieve statement');
                }
            },
            getFormattedDate(date) {
                return dayjs(date).format('MM/DD/YY');
            },
            async viewAsPdf() {
                const res = await this.$api.get(ledger.getStatementPdf(this.clientId, this.startDate, this.endDate));

                if (res.status < 300) {
                    openPdf(res.data, 'Statement');
                }
            },
            downloadPdf() {
                if (this.client) {
                    const fileName = `${this.client.first_name}_${this.client.last_name}_statement_${dayjs().format(
                        'YYYYMMDDHHmmss'
                    )}.pdf`;

                    downloadPdf(this.$refs.pdf, fileName);
                }
            },
            async sendPdfToClientFiles() {
                const res = await this.$api.post(ledger.saveStatementPdf(this.clientId, this.startDate, this.endDate));

                if (res.status < 300) {
                    this.$toasted.success("Successfully saved PDF to client's files");
                } else {
                    this.$toasted.error("Failed to save PDF to client's files. Please try again later");
                }
            }
        },
        async created() {
            await this.getStatement();
        }
    };
</script>

<style scoped>
    @media print {
        p {
            color: orange;
        }
    }
</style>
